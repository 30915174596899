<script lang="ts" setup>
const { t } = useT();
</script>

<template>
	<div class="register-gift">
		<AButton variant="primary">
			<AText :modifiers="['uppercase']">{{ t("Register") }}</AText>
		</AButton>
		<div class="gift">
			<NuxtImg src="/nuxt-img/auth/mini-gift.png" loading="lazy" format="webp" alt="gift" width="66" height="70" />
			<div class="star" />
			<div class="star-1" />
			<div class="star-2" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.register-gift {
	position: relative;
	@media (max-width: 1120px) {
		margin-right: gutter(0.5);
	}
	.gift {
		position: absolute;
		top: 0;
		right: -19px;
		width: 36px;
		height: 40px;
		overflow: hidden;
		z-index: 2;
		img {
			width: 34px;
			height: 35px;
			drop-shadow: 0 0 7px 0 var(--custom-rgb-11);
			animation: present 2.5s ease infinite;
		}
		.star,
		.star-1,
		.star-2 {
			position: absolute;
			z-index: -1;
			clip-path: polygon(
				99.75% 55.02%,
				72.38% 69.98%,
				60.6% 98.86%,
				37.91% 77.46%,
				6.8% 75.18%,
				20.15% 46.99%,
				12.7% 16.7%,
				43.64% 20.68%,
				70.15% 4.24%,
				75.92% 34.89%
			);
			animation: stars 2.5s linear infinite;
		}
		.star {
			top: 2px;
			right: 8px;
			width: 5px;
			height: 5px;
			background: var(--custom-34);
		}
		.star-1 {
			top: 7px;
			right: -1px;
			width: 7px;
			height: 7px;
			background: var(--custom-35);
		}
		.star-2 {
			top: 17px;
			right: 1px;
			width: 6px;
			height: 6px;
			background: var(--custom-29);
		}
	}
}
@keyframes present {
	50% {
		transform: rotate(-25deg);
	}
}
@keyframes stars {
	50% {
		top: 14px;
		right: 14px;
	}
}
</style>
